import * as React from 'react';
import type { GetServerSideProps, NextPage } from 'next';
import { signIn } from 'next-auth/react';
import { Session } from 'next-auth';
import { setCookie } from 'cookies-next';

import { getPortalOrAzureUser } from './api/auth/[...nextauth]';
import { context, trace, tracer } from '~/utils/telemetry/OTEL-instrumentation';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { defaultTextMapSetter, SpanStatusCode, SpanKind } from '@opentelemetry/api';
import { AWSXRayPropagator } from '@opentelemetry/propagator-aws-xray';
import { SemanticAttributes } from '@opentelemetry/semantic-conventions';
import Head from 'next/head';
import {pagesJson} from "../constants/pages";

const LoginPage: NextPage = () => {
  React.useEffect(() => {
    let cbroute: string | undefined = '/';

    const params = new URLSearchParams(window.location.search);
    const cbparm = params.get(pagesJson.Login.callbackrouteText);
    if (cbparm) {
      cbroute = cbparm;
      setCookie(pagesJson.Login.callbackrouteText, cbroute, { maxAge: 3600 });
    }

    void signIn(pagesJson.Login.azureAdB2cText, { callbackUrl: cbroute });
  }, []);

  return (
    <>
      <Head>
        <title>{pagesJson.Login.titleText}</title>
      </Head>
      <div className="flex-auto" />;
    </>
  )
};

const getServerSideProps: GetServerSideProps<{ session: Session | null }> =
  async ({ req, res, query }) => {
    let span;
    let session;

    try {
      span = tracer.startSpan('BillMgmt::'+process.env.ENV+'::Login', { kind: SpanKind.SERVER,
                                                                        attributes: {
                                                                          [SemanticAttributes.HTTP_METHOD]: "GET",
                                                                          [SemanticAttributes.HTTP_URL]: req.url,
                                                                          'PageName': 'Login',
                                                                        }});
      const propagator = new W3CTraceContextPropagator();      
      let carrier:{[key: string]: string} = {};

      propagator.inject(
        trace.setSpanContext(context.active(), span.spanContext()),
        carrier,
        defaultTextMapSetter
      );

      res.setHeader(pagesJson.Login.traceparentText,carrier.traceparent);
      carrier = {};

      const xraypropigator = new AWSXRayPropagator();
      xraypropigator.inject(
        trace.setSpanContext(context.active(), span.spanContext()),
        carrier,
        defaultTextMapSetter
      );

    res.setHeader(pagesJson.Login.xAmznTraceIdText,carrier[pagesJson.Login.xAmznTraceIdText]);

      [, session] = await getPortalOrAzureUser(req, res, query, true);

      
      if (!session || !session?.user?.accountNumber) {
        // return {props: {}}
        return {
          props: { session: null }
          // props: { session }
        };
      }

      return {
        redirect: {
          destination: '/',
          statusCode: 302,
        },
      };
    }
    catch(error) {
      console.error(error);
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR });

      return {
        props: { session },
      };
    }
    finally {
      span.end()
    }
  };

export default LoginPage;
export { getServerSideProps };
